import React, { useRef, useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from "@emotion/styled"

import { List, X } from "phosphor-react";

const Container = styled.div`
button {
    position: absolute;
    right: 4vw;
    background: transparent;
    border: 0;
    opacity: 0.4;
    top: 48%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
    svg {
            fill: black;
    }
    @media (max-width: 599px) {
        width: 40px;
    }
}
&[data-active='true'] nav {
    background-color: rgba(255,255,255,.9);
}
`

const Navigation = styled.nav`
opacity: 0;
visibility: hidden;
transition: all 0.3s ease;
&.active {
    opacity: 1;
    visibility: visible;
}
ul {
    @media (max-width: 599px) {
        display: grid;
        width: 92vw;
        margin: 0 4vw;
        grid-template-columns: repeat(4, 1fr);
    }
}
li {
    display: inline-block;
    font-size: .8125rem;
    text-align: right;
    padding-left: 4vw;
    list-style: none;
    color: rgba(0,0,0,0.5);
    letter-spacing: 2px;
    transition: all 0.3s ease;
    &:first-of-type {
        margin-left: 0;
    }
    @media (max-width: 599px) {
        display: block;
        text-align: center;
        padding: 0;
    }
}
a {
    text-decoration: none;
    color: rgba(0,0,0,0.5);
    transition: all 0.3s ease;
    &:hover {
        border-bottom: solid 1px #000;
        color: #000;
    }
}
a[aria-current='page'] {
    color: #000;
    border-bottom: solid 1px #000;
}
&.menu {
    @media (max-width: 599px) {
        right: 0 !important;
        top: 10vh;
        transform: none;
        width: 100%;
        padding-bottom: 2.5vh;
    }
}

`

const Mainnav = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulSiteNav {
            nodes {
                id
                title
              pages {
                id
                slug
                title
              }
            }
          }
    }
    `)
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const onClick = () => setIsActive(!isActive);
    const [state, setState] = useState({
        scrolled: false,
    });
    
    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 30;
          if (isScrolled !== state.scrolled) {
            setState({
              ...state,
              scrolled: !state.scrolled,
            });
          }
        };
        document.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
          document.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <Container data-active={state.scrolled}>
        <button onClick={onClick}>{isActive ? <X size={32} weight="light" color="#000000" /> : <List size={32} weight="light" color="#000000" />}</button>
        <Navigation ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
            {data.allContentfulSiteNav.nodes.map(node => (
            <ul>
            {node.pages.map(page => (
                <li key={page.id}><Link to={`/${page.slug}`}>{page.title}</Link></li>
                ))}
            </ul>
            ))}
        </Navigation>
        </Container>
    )
}

export default Mainnav